<template>
<div class="company">
    <div class="row">
        <div class="col">
            <img src="../assets/profile.png" alt="会社案内" class="img-fluid" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Breadcrumb :breadcrumbs="breadcrumbs"></Breadcrumb>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-3 mb-3">
            <div class="list-group text-center">
                <a href="/company/profile" class="list-group-item list-group-item-action" :class="{ active: isActive('/company/profile') }">
                    会社概要
                </a>
                <a href="/company/greeting" class="list-group-item list-group-item-action" :class="{ active: isActive('/company/greeting') }">
                    代表挨拶
                </a>
                <a href="/company/philosophy" class="list-group-item list-group-item-action" :class="{ active: isActive('/company/philosophy') }">
                    営理念
                </a>
                <a href="/company/organization" class="list-group-item list-group-item-action" :class="{ active: isActive('/company/organization') }">
                    組織図
                </a>
                <a href="/company/access" class="list-group-item list-group-item-action" :class="{ active: isActive('/company/access') }">
                    アクセス
                </a>
            </div>
        </div>
        <div class="col">
            <router-view />
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
    name: "CompanyPage",
    components: {
        Breadcrumb,
    },
    // ローカル リアクティブプロパティ
    data() {
        return {
            breadcrumbs: {
                data: [{
                        name: "ホーム",
                        path: "/home",
                    },
                    {
                        name: "会社案内",
                    },
                ],
            },
        };
    },
    // コンポーネントへのインタフェース
    props: {},
    computed: {},
    // リアクティブではないプロパティ
    methods: {
        isActive(path) {
            return this.$route.path === path;
        }
    },
    // イベント
    watch: {},
};
</script>
<style scoped>
.list-group-item.active {
  background-color: #00bfff;
  border-color: #00bfff;
}
</style>