<template>
<div class="contact">
    <div class="row">
        <div class="col">
            <img src="../assets/contact.png" alt="お問い合わせ" class="img-fluid" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Breadcrumb :breadcrumbs="breadcrumbs"></Breadcrumb>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h3>注意事項</h3>
            <div class="p-3 text-left">
                <p>
                    お問い合わせいただく前に、以下の注意事項をお読みの上、入力フォームをご利用いただきますようお願い申し上げます。<br />
                </p>
                <ul>
                    <li>
                        ご提供いただきました個人情報につきましては、当社の<a href="/privacypolicy">個人情報保護方針</a>に基づきお取り扱い致します。
                    </li>
                    <li>
                        お問い合わせの内容によっては、ご返答が遅れる場合がございます。ご了承ください。
                    </li>
                </ul>
            </div>
            <h3>お問い合わせフォーム</h3>
            <div class="p-3 text-left">
                <form method="post" v-on:submit.prevent="onSubmit" autocomplete="off" v-show="showForm">
                    <div class="mb-3">
                        <label for="name" class="form-label">お名前（漢字）</label>
                        <input type="input" class="form-control" id="name" v-model="name" required>
                    </div>
                    <div class="mb-3">
                        <label for="yomi" class="form-label">お名前（フリガナ）</label>
                        <input type="input" class="form-control" id="yomi" v-model="yomi" required>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">メールアドレス</label>
                        <input type="email" class="form-control" id="email" v-model="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                    </div>
                    <div class="mb-3">
                        <label for="body" class="form-label">お問い合わせ内容</label>
                        <textarea class="form-control" id="body" v-model="body" rows="5" max-rows="10" required></textarea>
                    </div>
                    <span class="required" v-show="hasError">通信時にエラーが発生しました。<br />ページを更新して再度お試しください。</span>
                    <div class="text-center">
                        <input class="btn btn-primary" type="submit" value="送信する">
                    </div>
                </form>
                <div class="text-center p-3" v-show="loading">
                    <img class="filter-gray" src="../assets/loading.svg" width="50" height="50" />
                </div>
                <div v-show="thanks">
                    お問い合わせの送信が完了しました。<br /><a href="/home">ホームへ移動する</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import axios from 'axios';

export default {
    name: "ContactPage",
    components: {
        Breadcrumb,
    },
    computed: {
        breadcrumbs: function () {
            return {
                data: [{
                        name: "ホーム",
                        path: "/home",
                    },
                    {
                        name: "お問い合わせ",
                    },
                ],
            };
        },
    },
    data() {
        return {
            name: "",
            yomi: "",
            email: "",
            body: "",
            hasError: false,
            loading: false,
            showForm: true,
            thanks: false,
        };
    },
    methods: {
        onSubmit: function () {
            var me = this;
            this.loading = true;
            axios
                .post(
                    "https://zftlnabczj.execute-api.ap-northeast-1.amazonaws.com/v1/send",
                    this.$data
                )
                .then(function (response) {
                    console.log(response);
                    me.showForm = false;
                    me.loading = false;
                    me.thanks = true;
                })
                .catch(function (error) {
                    console.log(error);
                    me.hasError = true;
                    me.showForm = true;
                    me.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.filter-gray {
    filter: invert(87%) sepia(4%) saturate(106%) hue-rotate(182deg) brightness(96%) contrast(87%);
}

.contact a {
    color: #28A7E1;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}
</style>
