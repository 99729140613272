<template>
<nav class="header navbar navbar-expand-lg bg-body-tertiary">
    <a class="navbar-brand logo" href="/home">
        <img src="../../assets/logo.png" alt="logo" width="50" height="50">
        <span class="company-name">株式会社フレンドシステムズ</span>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="nav ms-auto">
            <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/company/profile">会社案内</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/service/business">サービス</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/recruit">採用情報</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/contact">お問い合わせ</a>
            </li>
        </ul>
    </div>
</nav>
</template>

<script>
export default {
    name: "HeaderSection",
    components: {},
    data: function () {
        return {};
    },
    computed: {
        displayYMD() {
            return new Date().getFullYear().toString();
        },
    },
    beforeCreate: function () {},
    created: function () {},
    beforeMount: function () {},
    mounted: function () {},
    beforeUpdate: function () {},
    updated: function () {},
    activated: function () {},
    deactivated: function () {},
    beforeUnmount: function () {},
    unmounted: function () {},
    errorCaptured: function () {},
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
    .company-name {
        font-size: 80%;
    }
}
.company-name {
    color: #28A7E1;
    margin-left: 10px;
}

.header a:hover {
    text-decoration: none;
}

#navbarNav a {
    color: #2c3e50;
    text-decoration: none;
}
#navbarNav a:hover {
    color: #28A7E1;
    text-decoration: underline;
}
</style>
