<template>
<div class="securityPolicy">
    <div class="row">
        <div class="col">
            <img src="../assets/profile.png" alt="情報セキュリティ基本方針" class="img-fluid" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Breadcrumb :breadcrumbs="breadcrumbs"></Breadcrumb>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h3>情報セキュリティ基本方針</h3>
            <div class="p-3 text-left">
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;株式会社フレンドシステムズ（以下、当社といいます）は、
                    お客様やお取引先様からお預かりしたすべての情報資産に対する機密性、完全性、可用性の確保・向上に努め、
                    お客様を含む社会全体の信頼に応えるため、情報セキュリティ基本方針（以下、本方針）を定め、これを実施し推進することを宣言します。
                </p>
                <ol type="1" start="1" class="pt-3">
                    <li>
                        <mark>法令等の遵守</mark><br />
                        情報セキュリティに関する各種法令、国が定める指針およびその他規範を遵守します。
                    </li>
                    <li>
                        <mark>情報セキュリティ規程の制定</mark><br />
                        情報資産の保護および適切な管理を行うため、情報セキュリティに関する規程、基準等を制定し、社内に周知徹底します。
                    </li>
                    <li>
                        <mark>情報セキュリティ管理体制の確立</mark><br />
                        情報セキュリティ管理体制ならびにセキュリティインシデントの緊急時対応体制を整備すると共に、
                        情報セキュリティに関する責任者を配置し情報セキュリティ推進体制を構築します。
                    </li>
                    <li>
                        <mark>情報セキュリティ教育・訓練の実施</mark><br />
                        すべての従業者に対して、情報セキュリティの重要性の認識と、情報資産の適正な利用・管理のために必要な教育・訓練を実施します。
                    </li>
                    <li>
                        <mark>未然防止策</mark><br />
                        適切な人的・組織的・技術的施策を講じ、情報資産に対する不正な侵入、漏えい、改ざん、紛失、盗難、破壊、利用妨害などが発生しないよう努めます。
                    </li>
                    <li>
                        <mark>問題発生時の対応</mark><br />
                        事故が発生した場合には、迅速に対応し、被害を最小限にとどめるとともに再発防止のための措置を講じます。
                    </li>
                    <li>
                        <mark>定期的評価および継続的改善</mark><br />
                        情報セキュリティの取り組みを維持するとともに、経営環境や社会情勢の変化に対応するため、定期的に情報セキュリティに関する管理体制および対策実施状況を評価し、継続的な改善を図ります。
                    </li>
                </ol>
                <p class="text-end">
                    制定日：2018年1月18日<br />
                    改定日：2020年6月18日<br />
                    株式会社フレンドシステムズ<br />
                    代表取締役社長&nbsp;&nbsp;&nbsp;&nbsp;邵&nbsp;&nbsp;喜成
                </p>
                <p class="text-center bg-warning m-5 p-3">
                    <strong>情報セキュリティ基本方針に関するお問合せにつきましては、下記窓口で受付けております。</strong><br />
                    株式会社フレンドシステムズ&nbsp;&nbsp;情報セキュリティ管理者&nbsp;&nbsp;邵&nbsp;喜成<br />
                    〒101-0021&nbsp;東京都千代田区外神田2-2-17&nbsp;喜助お茶の水ビル86号室<br />
                    TEL：03-6271-7435<br />
                    受付時間：9:00～18:00<br />
                    ※土・日、祝日、弊社指定の休業日を除く
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
    name: "SecurityPolicyPage",
    components: {
        Breadcrumb,
    },
    computed: {
        breadcrumbs: function () {
            return {
                data: [{
                        name: "ホーム",
                        path: "/home",
                    },
                    {
                        name: "情報セキュリティ基本方針",
                    },
                ],
            };
        },
    },
    methods: {},
};
</script>

<style scoped>
ol li {
    padding-left: 10px;
    padding-bottom: 10px;
}
</style>
