<template>
<div class="philosophy">
    <h3>経営理念</h3>
    <div class="p-3">
        <h4>お客様に対して</h4>
        <p class="text-left">
            常にお客様の目線に立ち、真のニーズを理解するとともに、プロフェッショナルとしての知見を加え、お客様とともに最適な解決策を導きます。
        </p>
        <h4>社員に対して</h4>
        <p class="text-left">
            社員の人生がより価値あるものになるために、社員一人ひとりのキャリア形成を支援します。互いの人権・個性・価値観を尊重し、良好な職場環境を作ります。
        </p>
        <h4>社会に対して</h4>
        <p class="text-left">
            社会を支えるITシステムとそのセキュリティを担うことで、安心・安全な高度情報ネットワーク社会の実現やその発展に貢献します。
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: "PhilosophyPage",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
.philosophy p {
    line-height: 2em;
    padding: 10px;
}
</style>
