<!-- アプリケーションのメインテンプレート -->
<template>
    <!-- アプリケーションのルート要素 -->
    <div id="app" class="container">
        <!-- 共通ヘッダーコンポーネントの挿入 -->
        <common-header />
        <!-- ルータービュー：ページコンポーネントが描画される領域 -->
        <router-view />
    </div>
    <!-- 共通フッターコンポーネントの挿入 -->
    <common-footer />
</template>

<script>
// Vueコンポーネントをインポート
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
    // コンポーネントの名前
    name: "App",
    // 使用するコンポーネントの指定
    components: {
        CommonHeader, // 共通ヘッダー
        CommonFooter, // 共通フッター
    },
};
</script>

<style>
/* アプリケーション全体のスタイル */

#app {
    color: #2c3e50;
    /* テキストカラーの設定 */
}

/* ボディ要素のスタイル */

body {
    overflow-x: hidden;
    /* 横スクロールバーの表示を抑制 */
}

/* 画像のスタイル */

.img-fluid {
    max-width: 100%;
    /* 画像の最大幅を100%に設定 */
    height: auto;
    /* 高さを自動調整 */
    width: 100%;
    /* 幅を100%に設定 */
}

/* h3要素のスタイル */

h3 {
    padding: 10px;
    /* パディングの設定 */
    text-align: left;
    /* テキストの配置を左寄せ */
    border-left: 10px solid #00bfff;
    /* 左側に青色のボーダーを追加 */
    border-bottom: 1px solid #dedee0;
    /* 下側にグレーのボーダーを追加 */
}

/* h4要素のスタイル */

h4 {
    padding: 10px;
    /* パディングの設定 */
    text-align: left;
    /* テキストの配置を左寄せ */
    text-decoration: underline;
    /* 下線を追加 */
}</style>
