<template>
  <div class="home">
    <Slider />
    <Topics />
    <Service />
  </div>
</template>

<script>
import Slider from "@/components/home/Slider.vue";
import Topics from "@/components/home/Topics.vue";
import Service from "@/components/home/Service.vue";

export default {
  name: "HomePage",
  components: {
    Slider,
    Topics,
    Service,
  },
};
</script>
