<template>
<div class="product01">
    <div class="row">
        <div class="col">
            <h3>ホームページ構築</h3>
            <div class="text-left">
                <p class="p-3">
                    <mark>あなたのWebサイト、本当にレンタルサーバーが必要ですか？</mark><br /><br />
                    小～中規模のコーポレートサイトは、あまり更新の無く、静的なHTMLで会社概要、サービスやアクセスなどの情報を簡単に掲載することができますが、
                    問い合わせフォームを設置するためだけにレンタルサーバーを契約してPHPなどでサイトを構築していることが多いと思います。<br /><br />
                    弊社のホームページは、Amazon&nbsp;S3の静的サイトホスティングを使って、安価なサーバーレスの問い合わせフォームを実現することができていますので、
                    一度、運営されているWebサイトを「本当にレンタルサーバが必要だろうか？」と見直してみてはどうでしょう？
                </p>
            </div>
            <h4>技術キーワード</h4>
            <div class="p-3">
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="table-warning" width="200">開発言語</th>
                            <td>HTML、CSS、Vue.js（bootstrap、router、axios）、Node.js</td>
                        </tr>
                        <tr>
                            <th class="table-warning">クラウド基盤</th>
                            <td>Route53、CloudFront、Amazon&nbsp;S3、API&nbsp;Gateway、Lambda、SES</td>
                        </tr>
                        <tr>
                            <th class="table-warning">その他</th>
                            <td>Visual&nbsp;Studio&nbsp;Code、Docker（Vue開発環境構築）</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4>構成イメージ</h4>
            <div class="p-3">
                <img src="../../assets/product01/01.png" alt="全体設計" class="img-fluid" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Product01Page",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>
