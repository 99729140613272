<template>
<div class="organization">
    <h3>組織図</h3>
    <div class="p-3">
        <img src="../../assets/organization.png" alt="organization" />
    </div>
</div>
</template>

<script>
export default {
    name: "OrganizationPage",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
.organization img {
    max-width: 100%;
    height: auto;
}
</style>
