<template>
<div class="footer">
    <div class="footer-top bg-light text-dark">
        <div class="row">
            <div class="col-md-3 offset-md-1 p-3 text-center">
                <img class="footer-logo" src="../../assets/logo.png" alt="logo" />
                <p class="p-3">
                    明るく元気で前向きな気持ちの<br />あふれたあなたをお待ちしております。
                </p>
                <p><a href="/recruit">Join Our Team</a></p>
            </div>
            <div class="col-md-3 offset-md-1 p-3">
                <h4>Contact</h4>
                <div class="p-3">
                    <p>株式会社フレンドシステムズ</p>
                    <ol class="list-unstyled">
                        <li><i class="bi bi-building-add"></i>&nbsp;&nbsp;東京都千代田区外神田2-2-17<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;喜助お茶の水ビル86号室
                        </li>
                        <li>
                            <i class="bi bi-telephone-forward"></i>&nbsp;&nbsp;03-6271-7435
                        </li>
                        <li>
                            <i class="bi bi-printer"></i>&nbsp;&nbsp;03-6271-7436
                        </li>
                        <li>
                            <i class="bi bi-envelope-at"></i>&nbsp;&nbsp;info<i class="bi bi-c-circle"></i>friendsystems.co.jp
                        </li>
                    </ol>
                </div>
            </div>
            <div class="col-md-3 p-3">
                <h4>Links</h4>
                <div class="p-3">
                    <ol class="list-unstyled">
                        <li>
                            <a href="/privacyPolicy">個人情報保護について</a>
                        </li>
                        <li>
                            <a href="/securityPolicy">情報セキュリティ基本方針</a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="row">
            <p class="col-md-12 text-center mt-3">
                Copyright &copy; friendsystems.co.jp All Rights Reserved.
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "FooterSection",
    components: {},
    data: function () {
        return {};
    },
    computed: {
        displayYMD() {
            return new Date().getFullYear().toString();
        },
    },
    beforeCreate: function () {},
    created: function () {},
    beforeMount: function () {},
    mounted: function () {},
    beforeUpdate: function () {},
    updated: function () {},
    activated: function () {},
    deactivated: function () {},
    beforeUnmount: function () {},
    unmounted: function () {},
    errorCaptured: function () {},
};
</script>

<style scoped>
.footer a {
    color: #28A7E1;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.footer-logo {
    width: 100px;
}

.footer-bottom {
    background: #444;
    color: #aaa;
}
</style>
