<template>
<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li v-for="(breadcrumb, index) in breadcrumbs.data" :key="index" :class="{ 'breadcrumb-item': true, 'active': index === breadcrumbs.length - 1 }" :aria-current="index === breadcrumbs.length - 1 ? 'page' : null">
            <a v-if="breadcrumb.path" :href="breadcrumb.path">{{ breadcrumb.name }}</a>
            <span v-else>{{ breadcrumb.name }}</span>
        </li>
    </ol>
</nav>
</template>

<script>
export default {
    name: "BreadcrumbSection",
    props: [
        'breadcrumbs'
    ]
}
</script>

<style scoped>
/* ここに必要なスタイルを追加します */
nav {
  background-color: #f0f0f0; /* 目に優しい背景色 */
  padding: 5px; /* 全体の周りにスペースを追加 */
}
.breadcrumb {
  margin: 0px;
  padding: 0px;
}
.breadcrumb a {
    color: #28A7E1;
    text-decoration: none;
}
.breadcrumb a:hover {
    text-decoration: underline;
}
</style>
