<template>
<div class="access">
    <h3>アクセス</h3>
    <div class="p-3 text-left">
        <h4>本社所在地</h4>
        <p>〒101-0021&nbsp;東京都千代田区外神田2-2-17&nbsp;喜助お茶の水ビル86号室</p>
        <h4>交通アクセス</h4>
        <p>中央・総武線／御茶ノ水：徒歩5分<br />
            中央・総武線／秋葉原：徒歩6分<br />
            東京メトロ銀座線／末広町：徒歩8分
        </p>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.0802384465924!2d139.76543531535845!3d35.699643036569235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c1bf6530755%3A0xdccf7ae5cc63b7c3!2z44CSMTAxLTAwMjEg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy65aSW56We55Sw77yS5LiB55uu77yS4oiS77yR77yXIOWWnOWKqeOBiuiMtuOBruawtOODk-ODqw!5e0!3m2!1sja!2sjp!4v1668526589901!5m2!1sja!2sjp" height=500 allowfullscreen="allowfullscreen" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="width: 100%; border: none;"></iframe>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "AccessPage",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>
