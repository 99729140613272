<template>
<div class="privacyPolicy">
    <div class="row">
        <div class="col">
            <img src="../assets/profile.png" alt="個人情報保護について" class="img-fluid" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Breadcrumb :breadcrumbs="breadcrumbs"></Breadcrumb>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h3>個人情報保護方針</h3>
            <div class="p-3">
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;株式会社フレンドシステムズ（以下、当社といいます）は、
                    お客様の個人情報がプライバシーを構成する重要な情報であることを深く認識し、
                    業務において個人情報を取り扱う場合には、法令の遵守およびＩＳＯ２７０００シリーズ等に代表される公的規格類に準拠しながら、
                    情報の安全性・正確性を確保してまいりました。
                    また、以下のとおり個人情報保護方針を宣言し、個人情報保護マネジメントシステムを定め、
                    一層個人情報の保護を徹底することにより、お客様に安心と安全を提供し、その信頼に応えてまいります。
                </p>
                <ol type="1" start="1" class="pt-3">
                    <li>
                        <mark>個人情報の取得、利用、提供</mark><br />
                        当社は、事業活動の範囲内で個人情報の利用目的を特定し、その目的達成のために必要な限度で公正かつ適正に個人情報の取得、利用及び提供を行います。また、取得した個人情報の目的外利用をしないよう処置を講じます。
                    </li>
                    <li>
                        <mark>個人情報の適切な管理</mark><br />
                        当社は、当社が取り扱う個人情報について、不正アクセス、紛失、破壊、改ざん、漏えいなどの危険を十分に認識し、合理的な安全対策を実施するとともに、問題が発生した場合は適切な是正措置を講じます。
                    </li>
                    <li>
                        <mark>法令・規範の遵守</mark><br />
                        当社は、当社が保有する個人情報に関して適用される法令及びその他の規範を遵守します。
                    </li>
                    <li>
                        <mark>問い合わせへの対応</mark><br />
                        当社は、当社が取り扱う個人情報について、本人から開示、訂正、利用停止及び苦情相談等のお問い合わせがあった場合は適正に対応します。
                    </li>
                    <li>
                        <mark>継続的改善</mark><br />
                        当社は、個人情報保護に関する管理規定及び管理体制を整備し、全社員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。
                    </li>
                </ol>
                <p class="text-end">
                    制定日：2018年1月18日<br />
                    改定日：2020年6月18日<br />
                    株式会社フレンドシステムズ<br />
                    代表取締役社長&nbsp;&nbsp;&nbsp;&nbsp;邵&nbsp;&nbsp;喜成
                </p>
                <p class="text-center bg-warning m-5 p-3">
                    <strong>個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</strong><br />
                    株式会社フレンドシステムズ&nbsp;&nbsp;個人情報保護管理者&nbsp;&nbsp;邵&nbsp;喜成<br />
                    〒101-0021&nbsp;東京都千代田区外神田2-2-17&nbsp;喜助お茶の水ビル86号室<br />
                    TEL：03-6271-7435<br />
                    受付時間：9:00～18:00<br />
                    ※土・日、祝日、弊社指定の休業日を除く
                </p>
            </div>
            <h3>個人情報の取扱について</h3>
            <div class="p-3">
                <p>当社における個人情報の取り扱いについて以下に公表致します。</p>
                <h4>１．個人情報の利用目的について</h4>
                <div class="p-3">
                    <p>
                        当社で保有する個人情報は、次の利用目的の範囲内で適正に取り扱います。<br />
                        記載の利用目的以外で個人情報を利用する必要が生じた場合には、
                        あらかじめ本人の同意を得た場合、及び「個人情報の保護に関する法律」
                        その他条例により例外として取り扱うことが認められている場合を除き、
                        その利用についてご本人の同意をいただくものとします。
                    </p>
                    <table responsive>
                        <thead head-variant="light">
                            <tr>
                                <th>分類</th>
                                <th>利用目的</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>お取引先の個人情報</td>
                                <td>お取引先からの見積、注文、納品、検収などに係る業務</td>
                            </tr>
                            <tr>
                                <td>従業員の個人情報</td>
                                <td>従業者の雇用管理（給与・税務管理、健康管理、年金・保険管理及び連絡）
                                    および緊急時での連絡及び災害時に於ける対策及び安全管理等</td>
                            </tr>
                            <tr>
                                <td>退職者の個人情報</td>
                                <td>各種の連絡、法律上要求される諸手続</td>
                            </tr>
                            <tr>
                                <td>採用応募者に関する個人情報</td>
                                <td>採用情報等の提供・連絡及び採用業務</td>
                            </tr>
                            <tr>
                                <td>採用紹介会社からの応募者に対する個人情報</td>
                                <td>採用検討に関する連絡及びその後の可否連絡検討業務</td>
                            </tr>
                            <tr>
                                <td>お問い合せ、ご相談、クレーム等頂いた個人情報</td>
                                <td>上記事項への対応に係る業務</td>
                            </tr>
                            <tr>
                                <td>監視カメラにより取得した個人情報</td>
                                <td>防犯、警備目的</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>２．個人情報の委託について</h4>
                <p class="p-3">
                    当社では、業務の一部を外部に委託することがあります。
                    この場合、委託先の選定にあたっては十分な個人情報の保護水準を満たしているものを選定し、
                    秘密保持契約を締結する等、委託先の適切な監督に努めます。
                </p>
                <h4>３．個人情報等の第三者提供について</h4>
                <div class="p-3">
                    <p>
                        当社は、次の場合を除き、保有する個人情報を第三者には提供しません。
                    </p>
                    <ul>
                        <li>本人の同意がある場合</li>
                        <li>法令に基づき開示、提供を求められた場合</li>
                        <li>
                            人命、身体または財産の保護のために必要であって、本人の同意を取ることが困難な場合
                        </li>
                        <li>
                            利用目的の達成に必要な範囲で、個人情報の取り扱いを委託する場合
                        </li>
                        <li>
                            国または地方公共団体等が公的な事務を実施する上で、協力する必要がある場合であって、
                            本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                        </li>
                    </ul>
                </div>
                <h4>４．個人情報の開示等について</h4>
                <div class="p-3">
                    <p>
                        当社がご本人様又はその代理人様から、当社が保有する開示対象個人情報に関して利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止（以下、“開示等の請求”という。）にご対応させていただく場合の手続きは、下記のとおりです。
                    </p>
                    <div>
                        <ol type="A">
                            <li>
                                開示等の求めの申し出先<br />
                                開示等のお求めは、上記の個人情報保護方針に関するお問合せ窓口にお申し出ください。
                            </li>
                            <li>
                                開示等の求めに関するお手続き<br /><br />
                                <ol type="a">
                                    <li>
                                        お申し出受付け後、当社からご利用いただく所定の請求書様式「開示対象個人情報開示等申請書」を郵送いたします。
                                    </li>
                                    <li>
                                        ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、
                                        手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記の個人情報保護方針に関するお問合せ窓口までご郵送ください。
                                    </li>
                                    <li>
                                        上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうち
                                        ご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報をお問合せさせていただきます。
                                    </li>
                                    <li>
                                        回答は原則としてご本人に対して書面（封書郵送）にておこないます。
                                    </li>
                                </ol>
                            </li>
                            <li>
                                代理人によるお求めの場合、代理人であることを確認する資料<br />
                                開示等をお求めになる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してくださ
                                い。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。<br /><br />
                                <ol type="a">
                                    <li>
                                        委任状<br />
                                        ご本人様により委任状に捺印し、その印鑑の印鑑登録証明書を添付してください。
                                        代理人様が親権者などの法定代理人のときは、委任状に代えて、ご本人様との関係がわかる書類をご提出いただくことも可能です。
                                    </li>
                                    <li>
                                        代理人様本人であることを確認するための書類（コピー）
                                        <ul>
                                            <li>運転免許証</li>
                                            <li>パスポート</li>
                                            <li>健康保険の被保険者証</li>
                                            <li>住民票</li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                開示等のお求めに対する送料の負担と徴収方法<br />
                                1回の申請ごとに、800円の送料をご負担いただきます。800円分の郵便切手を申請書類にご同封下さい。
                            </li>
                        </ol>
                    </div>
                </div>
                <h4>５．個人情報等の開示等及び苦情・相談の窓口について</h4>
                <p class="p-3">
                    上記の個人情報保護方針に関するお問合せ窓口までお寄せください。
                </p>
                <h4>６．認定個人情報保護団体について</h4>
                <p class="p-3">当社が所属する認定個人情報保護団体はございません。</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
    name: "PrivacyPolicyPage",
    components: {
        Breadcrumb,
    },
    computed: {
        breadcrumbs: function () {
            return {
                data: [{
                        name: "ホーム",
                        path: "/home",
                    },
                    {
                        name: "個人情報保護について",
                    },
                ],
            };
        },
    },
    methods: {},
};
</script>

<style scoped>
ol li {
    padding-left: 10px;
    padding-bottom: 10px;
}
</style>
