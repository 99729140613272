<template>
<div class="greeting">
    <h3>代表挨拶</h3>
    <div class="p-3">
        <p>平素より、格別のご高配を賜り厚くお礼を申し上げます。<br />
            当社、株式会社フレンドシステムズは、2018年創業以来、 仲間と共にお客様の笑顔を創りだすシステムづくりを中核理念に置き、
            お客様と同じ目線で物事を考え、意識し、誠実に行動することが重要だと考えており、システムづくりに責任と思いやりを持って取り込んで参りました。
            <br /><br />
            今後は、今まで以上にお客さまの視点に立った提案、日々の細かい業務に対する改善を積み重ね、初心に帰って素直に実践することで、
            更なる成長を目指した最新技術（クラウドサービス・ビッグデータ・IOT・AIなど）への研究、投資を積極的に取り組んでまいりたいと思います。
            <br /><br />
            社員一丸となってお客様と共に成長し、経済の発展と活気のある豊かな社会に貢献してまいりますので、
            引き続き、倍旧のご支援・ご愛顧を賜りますよう、よろしくお願い申し上げます。
        </p>
        <p class="signature text-end">
            株式会社フレンドシステムズ<br />
            代表取締役社長&nbsp;&nbsp;&nbsp;&nbsp;邵&nbsp;&nbsp;喜成
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: "GreetingPage",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
.greeting p {
    line-height: 2em;
    padding: 10px;
}

.greeting p.signature {
    font-weight: 900;
}
</style>
