<template>
<div class="product02">
    <div class="row">
        <div class="col">
            <h3>IT人材派遣管理システム（ITDMS）</h3>
            <div class="text-left">
                <p class="p-3">
                    <mark>当社は本システムを導入することで、さまざまなメリットが得られます。貴社の業務効率を改善しませんか？</mark>
                </p>
                <ul>
                    <li>
                        <mark>工数・作業ミスの削減</mark><br />
                        システム上で勤怠・月報・休暇を入力することができるようになるため、勤怠管理部門は技術者から送られてくる勤怠表を待ち、不着の場合には連絡をするという業務が不要になりました。
                        何より、度々ミスを起こしていた勤務表を見ながらシステムに手入力する業務がなくなり、作業時間は今までの半分になりました。<br /><br />
                    </li>
                    <li>
                        <mark>データの一元化管理で管理業務の効率アップ</mark><br />
                        取引会社・営業者・技術者・注文情報など様々のデータをシステム上でまとめて管理できるようになったことで、
                        以前はバラバラとなっていた情報を一元化し、さらなる業務効率化の道を開く事ができました。
                    </li>
                </ul>
            </div>
            <h4>機能概要一覧</h4>
            <div class="p-3">
                <h5>技術者利用機能</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="table-warning" width="200">ユーザ認証機能</th>
                            <td>発行されたログインID（メールアドレス）とパスワードで、システムへログインする</td>
                        </tr>
                        <tr>
                            <th class="table-warning">ログアウト機能</th>
                            <td>ログイン済みのユーザがシステムからログアウトする</td>
                        </tr>
                        <tr>
                            <th class="table-warning">勤怠入力機能</th>
                            <td>月単位の勤怠情報（開始時刻、終了時刻、作業内容）を入力・更新する<br />
                                最新の勤怠情報をExcelファイルへ出力し、ダウンロードする</td>
                        </tr>
                        <tr>
                            <th class="table-warning">月報入力機能</th>
                            <td>月報情報（作業内容、進捗状況、トラブル状況、問題点、今後の予定、要望・意見）を入力・更新する<br />
                                最新の月報情報をExcelファイルへ出力し、ダウンロードする</td>
                        </tr>
                        <tr>
                            <th class="table-warning">休暇連絡機能</th>
                            <td>休暇情報をシステムに登録し、システム管理者へメールを送信する</td>
                        </tr>
                    </tbody>
                </table>
                <h5>管理者利用機能</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="table-warning" width="200">ホーム機能</th>
                            <td>ホーム画面に稼働中の技術者の基本情報、所属会社、営業者、勤怠入力状況、月報入力状況の一覧を表示する<br />
                                勤怠や月報が未入力の技術者へメールを送信し、入力するように通知する<br />
                                システムの通知メッセージを表示する</td>
                        </tr>
                        <tr>
                            <th class="table-warning">休日管理機能</th>
                            <td>祝日を管理する</td>
                        </tr>
                        <tr>
                            <th class="table-warning">休憩時間帯管理機能</th>
                            <td>作業時間から控除する所定の休憩時間帯を管理する</td>
                        </tr>
                        <tr>
                            <th class="table-warning">取引会社一覧機能</th>
                            <td>登録した取引会社の一覧を表示し、選択した取引会社の削除と更新を行う</td>
                        </tr>
                        <tr>
                            <th class="table-warning">取引会社登録機能</th>
                            <td>取引会社の情報を登録する</td>
                        </tr>
                        <tr>
                            <th class="table-warning">営業者一覧機能</th>
                            <td>登録した営業者の一覧を表示し、選択した営業者の削除と更新を行う</td>
                        </tr>
                        <tr>
                            <th class="table-warning">営業者登録機能</th>
                            <td>営業者の情報を登録する</td>
                        </tr>
                        <tr>
                            <th class="table-warning">技術者一覧機能</th>
                            <td>登録した技術者の一覧を表示し、選択した技術者の削除と更新を行う</td>
                        </tr>
                        <tr>
                            <th class="table-warning">技術者登録機能</th>
                            <td>技術者の情報を登録する</td>
                        </tr>
                        <tr>
                            <th class="table-warning">注文情報一覧機能</th>
                            <td>登録した注文情報の一覧を表示し、選択した注文情報の削除と更新を行う</td>
                        </tr>
                        <tr>
                            <th class="table-warning">注文情報登録機能</th>
                            <td>注文情報を登録する</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4>技術キーワード</h4>
            <div class="p-3">
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="table-warning" width="200">開発言語</th>
                            <td>Java（Spring&nbsp;Boot）、HTML、CSS、Vue.js（bootstrap）</td>
                        </tr>
                        <tr>
                            <th class="table-warning">クラウド基盤</th>
                            <td>Route53、Amazon&nbsp;S3、CloudWatch、ELB、VPC、EC2、RDS、AWS&nbsp;Auto&nbsp;Scaling、IAM、CodeCommit</td>
                        </tr>
                        <tr>
                            <th class="table-warning">その他</th>
                            <td>Eclipse、Docker（ローカルのDBサーバ構築）</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4>構成イメージ</h4>
            <div class="p-3">
                <img src="../../assets/product02/01.png" alt="全体設計" class="img-fluid" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Product02Page",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>
