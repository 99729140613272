import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Company from "../views/Company.vue";
import Profile from "../views/Company/Profile.vue";
import Greeting from "../views/Company/Greeting.vue";
import Organization from "../views/Company/Organization.vue";
import Access from "../views/Company/Access.vue";
import Philosophy from "../views/Company/Philosophy.vue";
import Service from "../views/Service.vue";
import Business from "../views/Service/Business.vue";
import Product01 from "../views/Service/Product01.vue";
import Product02 from "../views/Service/Product02.vue";
import Recruit from "../views/Recruit.vue";
import Contact from "../views/Contact.vue";
import Privacypolicy from "../views/PrivacyPolicy.vue";
import Securitypolicy from "../views/SecurityPolicy.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/company",
    name: "Company",
    component: Company,
    redirect: "/company/profile",
    children: [
      {
        path: "profile",
        component: Profile,
      },
      {
        path: "greeting",
        component: Greeting,
      },
      {
        path: "organization",
        component: Organization,
      },
      {
        path: "access",
        component: Access,
      },
      {
        path: "philosophy",
        component: Philosophy,
      },
    ],
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
    redirect: "/service/business",
    children: [
      {
        path: "business",
        component: Business,
      },
      {
        path: "product01",
        component: Product01,
      },
      {
        path: "product02",
        component: Product02,
      },
    ],
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: Recruit,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: Privacypolicy,
  },
  {
    path: "/securityPolicy",
    name: "SecurityPolicy",
    component: Securitypolicy,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
