<template>
<div id="carouselCaptions" class="carousel slide">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="../../assets/front_1.png" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block caption-animate">
                <h1>仲間と共にビジネスを創る</h1>
                <p>お客様の笑顔を創りだす「技術力」と「人間力」で最高のビジネスパートナーを目指します。</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../assets/front_2.png" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block caption-animate">
                <h1>私たちの価値観</h1>
                <p>人とITの力で新たな価値を創造し、世界の人々の豊かな生活と理想的な社会の実現に貢献します。</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../assets/front_3.png" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block caption-animate">
                <h1>弊社が選ばれる理由</h1>
                <p>海外まで広がる採用ネットワークを活かした豊富な供給力、コンプライアンスの順守、エンジニア向けキャリア支援など様々な理由があります。</p>
            </div>
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>
</template>

<script>
export default {
    name: "SliderSection",
    mounted() {
        // ページがロードされたときにアニメーションを開始する
        this.startAnimation();
    },
    methods: {
        startAnimation() {
            // アニメーションを適用する要素を取得
            const caption = document.querySelector('.caption-animate');
            // アニメーションを適用する
            caption.classList.add('animate__animated', 'animate__fadeInUp');
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
    .slider h1 {
        font-size: 1.2rem;
    }
    .slider p {
        font-size: 0.8rem;
    }
}

.carousel-item h1 {
    color: #fce303;
}

.carousel-item p {
    color: #FFFFFF;
}

.caption-animate {
    font-size: 1.2rem;
    /* 文字サイズを小さくする */
    animation: slideFromTop 2s ease-in-out;
    /* アニメーションの設定 */
    background-color: rgba(0, 191, 255, 0.7); /* #00bfff の色を透明度 0.7 で指定 */
    /* 黒色の半透明背景 */
}

@keyframes slideFromTop {
    0% {
        transform: translateY(-150%);
        /* 上から表示 */
        opacity: 0.5;
        /* 不透明度を0に設定 */
    }

    100% {
        transform: translateY(0);
        /* 下に移動 */
        opacity: 1;
        /* 不透明度を1に設定 */
    }
}
</style>
