// Vue.jsからcreateApp関数をインポートしています
import { createApp } from "vue";
// App.vueファイルをインポートしています
import App from "./App.vue";
// ルーターをインポートしています
import router from "./router";
// ストアをインポートしています
import store from "./store";
// BootstrapのCSSファイルをインポートしています
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Iconsのフォントファイルをインポートしています
import "bootstrap-icons/font/bootstrap-icons.min.css";
// Bootstrapを使用するために必要なJavaScriptファイルをインポートしています
import "bootstrap";

// Vueアプリケーションを作成しています
const app = createApp(App);

// アプリケーションにルーターを使用するよう設定しています
app.use(router);
// アプリケーションにストアを使用するよう設定しています
app.use(store);

// プロダクションヒントを無効に設定しています
app.config.productionTip = false;
// アプリケーションを指定した要素にマウントしています
app.mount("#app");
