<template>
<div class="service">
    <div class="row">
        <div class="col">
            <img src="../assets/service.png" alt="製品・サービス" class="img-fluid" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Breadcrumb :breadcrumbs="breadcrumbs"></Breadcrumb>
        </div>
    </div>
    <div class="row mt-3">
        <router-view />
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";

export default {
    name: "ServicePage",
    components: {
        Breadcrumb,
    },
    computed: {
        breadcrumbs: function () {
            return {
                data: [{
                        name: "ホーム",
                        path: "/home",
                    },
                    {
                        name: "製品・サービス",
                    },
                ],
            };
        },
    },
    methods: {},
};
</script>
