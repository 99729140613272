<template>
<div class="topics">
    <div class="row mt-3">
        <div class="col-md-6">
            <h3>トピックス</h3>
            <div class="p-3">
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td class="text-center">2021/08/18</td>
                            <td>ホームページをリニューアルしました。</td>
                        </tr>
                        <tr>
                            <td class="text-center">2021/01/18</td>
                            <td>東京千代田区に本社を移転しました。</td>
                        </tr>
                        <tr>
                            <td class="text-center">2018/02/01</td>
                            <td>株式会社HBA様との取引が始まりました。</td>
                        </tr>
                        <tr>
                            <td class="text-center">2018/01/18</td>
                            <td>株式会社フレンドシステムズが設立しました。</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6">
            <h3>ビジネスパートナー募集</h3>
            <div class="p-3">
                <p>当社では、SES（常駐型技術支援サービス）および受託開発分野において、ビジネスパートナー様を積極的に募集しております。<br /><br />
                    ビジネスパートナー様との協業に際し、信頼と共に長期的な関係を築くことを重視しています。<br />
                    お互いの成長と発展を目指し、Win-Winの関係を築きましょう。<br /><br />
                    ご興味をお持ちいただけましたら、お気軽にお問い合わせください。</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "TopicsSection",
}
</script>

<style scoped>
</style>
