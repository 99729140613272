<template>
<div class="profile">
    <h3>会社概要</h3>
    <div class="p-3">
        <table class="table">
            <tbody>
                <tr>
                    <th class="table-warning text-center">会社名</th>
                    <td>株式会社フレンドシステムズ（略称：FS）<br />Friend Systems
                        Corporation</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">設立</th>
                    <td>2018年1月18日</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">資本金</th>
                    <td>1000万円</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">決算</th>
                    <td>年1回（12月）</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">役員</th>
                    <td>代表取締役社長&nbsp;&nbsp;邵喜成<br />取締役&nbsp;&nbsp;スニン
                        ウェイ</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">従業員数</th>
                    <td>10名（2023年6月現在）</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">本社所在地</th>
                    <td>〒101-0021<br />東京都千代田区外神田2-2-17
                        喜助お茶の水ビル86号室</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">TEL</th>
                    <td>03-6271-7435</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">FAX</th>
                    <td>03-6271-7436</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">税理士事務所</th>
                    <td>森田税理士・社労士事務所</td>
                </tr>
                <tr>
                    <th class="table-warning text-center">取引銀行</th>
                    <td>三菱UFJ銀行<br />みずほ銀行<br />楽天銀行</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
    name: "ProfilePage",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>
