import { createStore } from "vuex";

export default createStore({
  state: {
    // アクセスカウントを管理するstate
    accessCount: 0,
  },
  mutations: {
    incrementAccessCount(state) {
      // アクセスカウントを増やすmutation
      state.accessCount++;
    },
  },
  actions: {
    incrementAccessCount({ commit }) {
      // アクセスカウントを増やすaction
      commit("incrementAccessCount");
    },
  },
  getters: {
    getAccessCount(state) {
      // アクセスカウントを取得するgetter
      return state.accessCount;
    },
  },
});
